import { saveAs } from "file-saver";
import { useState } from "react";
import * as constants from "../constants.js";

/*
 * Component that handles the download feature and returns the download button. Handles a query that
 * obtain the daily reports of one id. When there is a list of multiple ids, this query is executed
 * the number of ids in that list. Stores the information on a CSV file for each nest. After finishing,
 * a zip file is created and prompts the user to download.
 *
 * Uses jszip and file-saver to save the json results from the query to the CSV and zip file. Title of the
 * CSV is the nest's id and the title of the zip file are the dates selected.
 */

const {
  Parser,
  transforms: { unwind },
} = require("json2csv");

const zip = require("jszip")();
let emptyNests = "";
let emptyCounter = 0;

function Download(props) {
  const [downloadText, setDownloadText] = useState("Download");

  const foldername =
    convertDateToQuery(props.selectedDates[0]) +
    " - " +
    convertDateToQuery(props.selectedDates[1]);

  function convertDateToQuery(date) {
    return date.toISOString().substring(0, 10);
  }

  // Function that handles the query for an id along the dates selected. When the query is
  // done, runs the function generateData that passes the id and the data.
  async function fetchMonitorDataQuery(monitorId, from, to) {
    return new Promise(function (resolve, reject) {
      fetch(constants.REACT_APP_GRAPHQL_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: `
    query GetMonitorData($to: String!, $from: String!, $monitorId: String!) {
      getDailyReportsById(to: $to, from: $from, monitorId: $monitorId) {
        id
        time_stamp
        reports {
          hour
          temperature
          humidity
        }
      }
    }
      `,
          variables: {
            from: from,
            to: to,
            monitorId: monitorId,
          },
        }),
      })
        .then((res) => res.json())
        .then((result) => generateData(monitorId, result.data))
        .then(() => resolve());
    });
  }

  // Parses the json from the query to a proper csv format, then saves it as a csv file.
  // If the json is empty, insert the id into a list called emptyNests.
  async function generateData(monitorId, data) {
    return new Promise(function (resolve, reject) {
      const fields = [
        "reports.hour",
        "reports.temperature",
        "reports.humidity",
      ];
      const transforms = [unwind({ paths: ["reports"] })];
      const json2csvParser = new Parser({ fields, transforms });
      const csv = json2csvParser.parse(data.getDailyReportsById);
      if (data.getDailyReportsById[0] != null) {
        zip
          .folder(foldername)
          .file(data.getDailyReportsById[0].id + ".csv", csv);
      } else {
        if (emptyCounter !== 0) emptyNests += ",";
        emptyNests += " " + monitorId;
        emptyCounter++;
      }
      resolve();
    });
  }

  // This is the final step of the feature. Checks for data inside the emptyNest list.
  // If the size of the emptyNest list is the same as the list of ids, alerts the user that there is
  // no data to download. If there is at least one element in the emptyNest list, prompts the user a list
  // of empty nests then downloads with the nests that do have data. Downloads normally if
  // all the nests selected are filled with data.
  async function downloadData() {
    if (emptyCounter === props.listOfIDs.length) {
      alert("Based on the selected dates, no stored data was found.");
    } else {
      if (emptyCounter > 0) {
        let alertMessage =
          "Based on the selected dates, the following nests are empty: " +
          emptyNests;
        alert(alertMessage);
      }
      zip.generateAsync({ type: "blob" }).then(function (blob) {
        saveAs(blob, foldername);
      });
      zip.remove(foldername);
    }
    emptyNests = "";
    emptyCounter = 0;
  }

  // First of step of the feature. For each id in props.listOfIDSs, runs a query that obtains
  // the data of the nest ranging from the dates selected.
  async function clickedDownload() {
    setDownloadText("Downloading...");
    for (const id of props.listOfIDs) {
      await fetchMonitorDataQuery(
        id,
        convertDateToQuery(props.selectedDates[0]),
        convertDateToQuery(props.selectedDates[1]) + "T23:59:59"
      );
    }
    await downloadData();
    setDownloadText("Download");
  }

  return (
    <div>
      {downloadText === "Download" ? (
        <button
          className="bg-green-600 hover:bg-green-500 text-white font-medium px-4 py-2 rounded"
          onClick={() => clickedDownload()}
        >
          {downloadText}
        </button>
      ) : (
        <button className="bg-gray-500 pointer-events-none text-white font-medium px-4 py-2 rounded">
          {downloadText}
        </button>
      )}
    </div>
  );
}

export default Download;
