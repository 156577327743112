import React from "react";
import { useTable, useSortBy } from "react-table";

/*
 * Component that handles the table of measurements for the nest page. Constant list called
 * temp that will contain the desire values and template for the table in the form:
 * date, temperature, humidity
 */

const temp = [];

function MeasurementTable(props) {
  temp.length = 0;

  for (var i = props.data.length - 1; i >= 0; i--) {
    for (var j = props.data[i].reports.length - 1; j >= 0; j--) {
      const e = props.data[i].reports[j];
      temp.push({
        date: e.hour,
        temperature:
          e.temperature < 50 || e.temperature > 130
            ? e.temperature + " F (!!!)"
            : e.temperature + " F",
        humidity:
          e.humidity > 100 || e.humidity < 0
            ? e.humidity + " % (!!!)"
            : e.humidity + " %",
      });
    }
  }

  const data = React.useMemo(() => temp, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "Date",
        accessor: "date",
        width: 200,
      },
      {
        Header: "Temperature",
        accessor: "temperature",
        width: 200,
      },
      {
        Header: "Humidity",
        accessor: "humidity",
        width: 200,
      },
    ],
    []
  );

  return <Table columns={columns} data={data} />;
}

function Table({ columns, data }) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useSortBy
    );

  // Template for the code: https://react-table-v7.tanstack.com/docs/quick-start
  // Populated the table and imported useSortBy from react-table itself that handles
  // the sorting for each column.
  return (
    <div className="flex flex-col overflow-hidden">
      <div className="overflow-auto">
        <div className="pt-1.5 w-full">
          <div className="border rounded-lg">
            <table {...getTableProps()} className="min-w-full">
              <thead className="bg-slate-300 hover:cursor-pointer">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        {...column.getHeaderProps({
                          style: { width: column.width },
                        })}
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left text-black uppercase"
                      >
                        {column.render("Header")}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? " 🔽"
                              : " 🔼"
                            : ""}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody
                {...getTableBodyProps()}
                className="divide-y divide-gray-400"
              >
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap bg-white"
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MeasurementTable;
