import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Gallery from "../components/Gallery";
import ImageViewer from "../components/ImageViewer";
import MeasurementTable from "../components/MeasurementTable";
import SelectionPanel from "../components/SelectionPanel";

/*
 * The main layout for the nest page. Contains two states: imageList for the list of photos
 * and imageValues for the current selected photos. Contains several components for the page
 * such as: Navbar, Gallery, ImageViewer, MeasurementTable, and SelectionPanel. The layout
 * of the page was made with Grid.
 */

function NestPage(props) {
  const [imageList] = useState(props.media);
  const [imageValues, setImageValues] = useState(
    imageList.length === 0
      ? ["", ""]
      : [
          imageList[imageList.length - 1].file_key,
          imageList[imageList.length - 1].time_stamp,
        ]
  );

  // Callback when user selects another image.
  function nestPageHandleInputCallback(file_key, time_stamp) {
    setImageValues([file_key, time_stamp]);
  }

  // Callback when user selects a new date and clicks the apply button.
  function changeDate(newDate) {
    props.callback(newDate);
  }

  return (
    <div className="grid lg:grid-cols-[442px_1fr_30%] lg:grid-rows-[50px_3fr_1fr] grid-cols-1 grid-rows-[50px_500px_1fr_500px_1fr] w-full h-screen">
      <div className="lg:col-span-3">
        <Navbar />
      </div>
      <div className="grid lg:row-span-2">
        <Gallery
          monitorId={props.monitorId}
          imageList={imageList}
          callback={nestPageHandleInputCallback}
        />
      </div>
      <ImageViewer
        monitorId={props.monitorId}
        fileKey={imageValues[0]}
        time_stamp={imageValues[1]}
      />
      <div className="grid lg:row-span-2">
        <MeasurementTable data={props.measurements} />
      </div>
      <SelectionPanel
        monitorId={props.monitorId}
        selectedDates={props.selectedDates}
        callback={changeDate}
      />
    </div>
  );
}

export default NestPage;
