import { React, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import NestPage from "./NestPage";
import { useParams, useLocation } from "react-router-dom";
import Loading from "../components/Loading";
import NotFound from "../components/NotFound";

/*
 * Provides the query for the NestPage. Two queries that collect the daily reports
 * and another that retrieves the photos taken of the current nest. Both of these
 * queries have a "from" and "to" input parameters (date selection) that handles
 * the range of data the queries will return.
 */

const GET_MONITOR_LIST = gql`
  query GetMonitorData($to: String!, $from: String!, $monitorId: String!) {
    getDailyReportsById(to: $to, from: $from, monitorId: $monitorId) {
      id
      time_stamp
      reports {
        hour
        temperature
        humidity
      }
    }
    getMediaFilesById(to: $to, from: $from, monitorId: $monitorId) {
      file_key
      time_stamp
    }
  }
`;

/* First function that checks if the url is valid and the user clicked a nest from the table.
 * useLocation().state contains the check_in value. If its null, user attempted to access site through
 * an invalid url or by typing it.
 */
function CheckValidURL() {
  if (useLocation().state != null) return <NestPageQueryLoader />;
  return <NotFound />;
}

/*
 * Contains two state dates, selectedDates and queryDates, for the datePicker in the SelectionPanel
 * component and as an input parameter for the query, respectively. Dates are modified to convert
 * from UTC to AST so there isn't incorrect selection of dates.
 */
function NestPageQueryLoader() {
  // Whenever a user clicks on a nest, id passes on to the page.
  const { id } = useParams();

  const [selectedDates, setSelectedDates] = useState([
    new Date(
      convertUTCtoAST(useLocation().state.check_in).substring(0, 10) +
      "T00:00:00"
    ),
    new Date(
      convertUTCtoAST(useLocation().state.check_in).substring(0, 10) +
      "T00:00:00"
    ),
  ]);

  const [queryDates, setQueryDate] = useState([
    convertDateToQuery(selectedDates[0]),
    convertDateToQuery(selectedDates[1]) + "T23:59:59",
  ]);

  function convertUTCtoAST(date) {
    //Initial conversion from UTC to AST
    let obj = new Date(date);
    obj.setTime(obj.getTime() - 4 * 60 * 60 * 1000);
    return obj.toISOString();
  }

  function convertDateToQuery(date) {
    //Convert to ISO format without timezone conversion
    // convert the local time zone offset from minutes to milliseconds
    let zone = date.getTimezoneOffset() * 60 * 1000;
    // subtract the offset from date
    let timeLocal = date - zone;
    // create shifted Date object
    timeLocal = new Date(timeLocal);
    //return in ISO format without hour
    return timeLocal.toISOString().substring(0, 10);
  }

  function changeDate(newDate) {
    if (newDate !== selectedDates) {
      setSelectedDates(newDate);
      setQueryDate([
        convertDateToQuery(newDate[0]),
        convertDateToQuery(newDate[1]) + "T23:59:59",
      ]);
    }
  }

  const { data, loading, error } = useQuery(GET_MONITOR_LIST, {
    variables: {
      monitorId: id,
      from: queryDates[0],
      to: queryDates[1],
    },
    fetchPolicy: "no-cache",
  });

  if (loading) return <Loading />;

  if (error) return <pre>{error.message}</pre>;

  return (
    <div>
      <NestPage
        monitorId={id}
        measurements={data.getDailyReportsById}
        media={data.getMediaFilesById}
        selectedDates={selectedDates}
        callback={changeDate}
      />
    </div>
  );
}

export default CheckValidURL;
