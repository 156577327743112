import { Link } from "react-router-dom";

/*
 * Top bar of the page to display the project name and as a button to return to the
 * homepage.
 */

function Navbar() {
  return (
    <nav className="flex bg-gradient-to-br from-red-600 to-purple-600 px-6">
      <Link to="/" className="text-gray-200 hover:no-underline py-2 px-2.5">
        <h1 className="text-2xl font-bold">ECO PR: Smart Nest</h1>
      </Link>
    </nav>
  );
}

export default Navbar;
