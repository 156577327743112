import { React, useState } from "react";
import Buttons from "./Buttons";
import DatePick from "./DatePick";

/*
 * Component that contains the panel such as the input boxes for the dstea, apply, and
 * download buttons. Contains a newDate state that contains the selection of dates.
 */

function SelectionPanel(props) {
  const [newDate, setNewDates] = useState(props.selectedDates);

  function changeDate() {
    props.callback(newDate);
  }

  function changeNewDate(from, to) {
    setNewDates([from, to]);
  }

  return (
    <div className="grid bg-slate-300 grid-cols-2 grid-rows-3 gap-2 place-items-center max-h-[300px]">
      <p className="text-center w-3/4 col-span-2 font-bold">
        Selected the dates, click the apply or download button to view or
        download the data, respectively
      </p>
      <div className="row-span-2">
        <label className="font-bold">From:</label>
        <DatePick
          selectedDates={newDate}
          fromSelected={true}
          callback={changeNewDate}
        />
      </div>
      <div className="row-span-2">
        <label className="font-bold">To:</label>
        <DatePick
          selectedDates={newDate}
          fromSelected={false}
          callback={changeNewDate}
        />
      </div>
      <div className="pb-4">
        <Buttons
          listOfIDs={props.monitorId}
          selectedDates={newDate}
          callback={changeDate}
          type="Apply"
        />
      </div>
      <div className="pb-4">
        <Buttons
          listOfIDs={[props.monitorId]}
          selectedDates={newDate}
          type="Download"
        />
      </div>
    </div>
  );
}

export default SelectionPanel;
