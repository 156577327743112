import { Link } from "react-router-dom";

/*
 * Component that handle the row of the table for the homePage. When user clicks
 * on the name of a nest, the id along the check_in is passed to the nestPage.
 */

const Checkbox = ({ id, isSelected, onCheckboxChange, check_in }) => (
  <div>
    <input
      className="form-check-input appearance-none h-4 w-4 border border-black rounded-sm bg-white checked:bg-green-600 checked:border-green-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
      type="checkbox"
      name={id}
      checked={isSelected}
      onChange={onCheckboxChange}
    />
    <Link
      to={"/nest/" + id}
      state={{ check_in: check_in }}
      className="pl-6 hover:text-green-600 hover:font-bold"
    >
      {id}
    </Link>
  </div>
);

export default Checkbox;
