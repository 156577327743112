import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

/*
 * Component that handles the selection of dates. Imports a library called react-datepicker.
 */

function DatePick(props) {
  return (
    <div>
      <DatePicker
        selected={
          props.fromSelected ? props.selectedDates[0] : props.selectedDates[1]
        }
        onChange={(date) =>
          props.fromSelected
            ? props.callback(date, props.selectedDates[1])
            : props.callback(props.selectedDates[0], date)
        }
        dateFormat="MM/dd/yyyy"
        maxDate={new Date()}
      />
    </div>
  );
}

export default DatePick;
