import React from "react";
import Checkboxes from "../components/Checkboxes";
import Buttons from "../components/Buttons";
import Navbar from "../components/Navbar";
import DatePick from "../components/DatePick";

/*
 * Main layout for the homePage. Contains two states: monitors that will hold which checkbox
 * the user has selected by inserting false for every nest and selectedDates that contain the
 * dates.
 */

class HomePage extends React.Component {
  state = {
    monitors: this.props.nests.reduce(
      (options, option) => ({ ...options, [option.id]: false }),
      {}
    ),
    selectedDates: [null, null],
  };

  changeDate = (from, to) => {
    this.setState({
      selectedDates: [from, to],
    });
  };

  handleFormSubmit = (formSubmitEvent) => {
    formSubmitEvent.preventDefault();
  };

  // Function when user has clicked the download button, selected checkboxes and nests. Returns
  // a list that were true on the monitors state.
  downloadList = () => {
    return Object.keys(this.state.monitors).filter(
      (checkbox) => this.state.monitors[checkbox]
    );
  };

  // When user clicks a checkbox, changes from false to true or viceversa on the monitor state list.
  handleCheckboxChange = (changeEvent) => {
    const { name } = changeEvent.target;
    this.setState((prevState) => ({
      monitors: {
        ...prevState.monitors,
        [name]: !prevState.monitors[name],
      },
    }));
  };

  // Function that modifies the whole monitor list to be true or false depending on isSelected.
  selectAllCheckboxes = (isSelected) => {
    Object.keys(this.state.monitors).forEach((checkbox) => {
      this.setState((prevState) => ({
        monitors: {
          ...prevState.monitors,
          [checkbox]: isSelected,
        },
      }));
    });
  };

  selectAll = () => this.selectAllCheckboxes(true);

  deselectAll = () => this.selectAllCheckboxes(false);

  render() {
    return (
      <div className="grid grid-cols-1 lg:grid-cols-3 bg-slate-200 w-full overflow-x-auto">
        <div className="lg:col-span-3">
          <Navbar />
        </div>
        <div className="grid">
          <p className="text-center pt-4">
            To download data from multiple nests:
            <br />
            Select the checkboxes next to the monitor IDs, specify the dates,
            and then click the download button.
          </p>
          <div className="flex justify-center pt-4 gap-20">
            <button
              className="bg-green-600 hover:bg-green-500 text-white font-medium px-4 py-2 rounded"
              onClick={this.selectAll}
            >
              Select All
            </button>
            <button
              className="bg-green-600 hover:bg-green-500 text-white font-medium px-4 py-2 rounded"
              onClick={this.deselectAll}
            >
              Deselect All
            </button>
          </div>
        </div>
        <div className="grid">
          <p className="text-center mt-auto mb-auto">
            To view images and data for a specific nest:
            <br />
            Click on the ID of the monitor.
          </p>
        </div>
        <div className="grid">
          <div className="flex flex-wrap justify-center gap-10 pt-4">
            <div>
              <label>From:</label>
              <DatePick
                selectedDates={this.state.selectedDates}
                fromSelected={true}
                callback={this.changeDate}
              />
            </div>
            <div>
              <label>To:</label>
              <DatePick
                selectedDates={this.state.selectedDates}
                fromSelected={false}
                callback={this.changeDate}
              />
            </div>
          </div>
          <div className="pt-4 mr-auto ml-auto">
            <form onSubmit={this.handleFormSubmit}>
              <Buttons
                listOfIDs={this.downloadList()}
                selectedDates={this.state.selectedDates}
                type="Download"
              />
            </form>
          </div>
        </div>
        <div className="lg:col-span-3 w-full">
          <Checkboxes
            listOfNests={this.props.nests}
            checkboxes={this.state.monitors}
            callback={this.handleCheckboxChange}
          />
        </div>
      </div>
    );
  }
}

export default HomePage;
