import React from "react";
import { gql, useQuery } from "@apollo/client";
import HomePage from "./HomePage";
import Loading from "../components/Loading";

/*
 * Handles the query for the homepage. Gets a list of nests to display the values
 * id, last_check_in, and status on a table. Whenever the page is loading, returns
 * the component Loading. If the page couldn't load, returns an error message.
 */

const GET_MONITOR_LIST = gql`
  query GetMonitorList {
    getMonitorList {
      id
      last_check_in
      status
    }
  }
`;

function HomePageQueryLoader() {
  const { data, loading, error } = useQuery(GET_MONITOR_LIST);
  if (loading) return <Loading />;

  if (error) return <pre>{error.message}</pre>;

  return <HomePage nests={data.getMonitorList} />;
}

export default HomePageQueryLoader;
