// Simple component that returns a backdrop for the pop-up message on the modal component.

function Backdrop(props) {
  return (
    <div
      className="absolute z-10 bg-gray-700/90 top-0 left-0 w-full h-full"
      onClick={props.onClick}
    />
  );
}

export default Backdrop;
