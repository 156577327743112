import { useState } from "react";
import Modal from "./Modal";
import Backdrop from "./Backdrop";
import Download from "./Download";

/*
 * Component that contains the functionality of the apply and download button.
 * When the dates aren't selected, returns a gray button. When the dates are invalid,
 * the button will return the modal with the error message. If the button is apply,
 * makes a callback to change the dates. When the button is download, passs the value
 * to the download component.
 */

function PanelButtons(props) {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  function openModalHandler() {
    setModalIsOpen(true);
  }

  function closeModalHandler() {
    setModalIsOpen(false);
  }

  function apply() {
    props.callback();
  }

  if (
    props.selectedDates[0] === null ||
    props.selectedDates[1] === null ||
    props.listOfIDs.length === 0
  )
    return <GrayButton type={props.type} />;
  else if (props.selectedDates[0] > props.selectedDates[1]) {
    return (
      <div>
        <InvalidButton type={props.type} />
        {modalIsOpen && <Backdrop onClick={closeModalHandler} />}
        {modalIsOpen && (
          <Modal
            onClick={closeModalHandler}
            text={"Please enter a valid date range"}
          />
        )}
      </div>
    );
  } else {
    if (props.type === "Download")
      return (
        <Download
          listOfIDs={props.listOfIDs}
          selectedDates={props.selectedDates}
        />
      );
    else return <ApplyButton />;
  }

  //-----------------------------------TailwindCSS-----------------------------------//

  function GrayButton(props) {
    return (
      <button className="bg-gray-500 pointer-events-none text-white font-medium px-4 py-2 rounded">
        {props.type}
      </button>
    );
  }

  function InvalidButton(props) {
    return (
      <button
        className="bg-green-600 hover:bg-green-500 text-white font-medium px-4 py-2 rounded"
        onClick={openModalHandler}
      >
        {props.type}
      </button>
    );
  }

  function ApplyButton() {
    return (
      <button
        className="bg-green-600 hover:bg-green-500 text-white font-medium px-4 py-2 rounded"
        onClick={apply}
      >
        Apply
      </button>
    );
  }
}

export default PanelButtons;
