import image from "./warning.png";

/*
 * Component that handles the pop up message when user selects an invalid date.
 */

function Modal(props) {
  return (
    <div className="flex justify-center items-center absolute top-0 right-0 bottom-0 left-0">
      <div className="bg-white px-16 py-14 rounded-md text-center z-10">
        <div className="flex gap-3 pb-5">
          <img src={image} alt="warning" className="h-10" />
          <h1 className="text-xl mb-4 font-bold text-slate-500 pt-2">
            {props.text}
          </h1>
        </div>
        <button
          className="bg-green-600 hover:bg-green-500 text-white font-medium px-4 py-2 rounded"
          onClick={props.onClick}
        >
          Continue
        </button>
      </div>
    </div>
  );
}

export default Modal;
