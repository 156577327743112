import * as constants from "../constants.js";
import image from "./placeholder.png";

/*
 * Component that displays an in the middle of the screen when user clicks on one of the photos
 * from the gallery. Takes the id and filekey of the nest to display it.
 */

function ImageViewer(props) {
  const fileUrl =
    constants.REACT_APP_CLOUDFRONT_FILE_URL +
    props.monitorId +
    "/fotos/" +
    props.fileKey;

  return (
    <div className="grid h-full">
      <h4 className="font-bold text-center">
        {props.fileKey !== ""
          ? new Date(props.time_stamp).toUTCString().substring(0, 25) + " AST"
          : "No images"}
      </h4>
      {props.fileKey !== "" ? (
        <img
          className="ml-auto mr-auto shadow-lg border-4 border-black rounded w-[95%]"
          src={fileUrl}
          alt={fileUrl}
        ></img>
      ) : (
        <img
          className="ml-auto mr-auto shadow-lg border-4 border-black rounded w-[85%]"
          src={image}
          alt={"placeholder"}
        ></img>
      )}
    </div>
  );
}

export default ImageViewer;
