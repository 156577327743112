import Checkbox from "./Checkbox";

/*
 * Component that handles the table for the homepage. Imports checkbox component to pass in the values
 * of the nest.
 */

function Checkboxes(props) {
  return (
    <div className="flex flex-col overflow-x-auto">
      <div className="p-1.5 w-full">
        <div className="border rounded-lg">
          <table className="min-w-full">
            <thead className="bg-slate-300">
              <tr>
                <th className="px-6 py-3 text-xs font-bold text-left text-black uppercase w-[30%]">
                  ID
                </th>
                <th className="px-6 py-3 text-xs font-bold text-left text-black uppercase">
                  Last Check-in
                </th>
                <th className="px-6 py-3 text-xs font-bold text-left text-black uppercase w-[15%]">
                  Status
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-400">
              {props.listOfNests.map((e) => (
                <tr key={e.id}>
                  <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap bg-white">
                    <Checkbox
                      id={e.id}
                      isSelected={props.checkboxes[e.id]}
                      onCheckboxChange={props.callback}
                      check_in={e.last_check_in}
                    />
                  </td>
                  <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap bg-white">
                    {new Date(e.last_check_in).toString()}
                  </td>
                  <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap bg-white">
                    {e.status}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Checkboxes;
