import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import * as constants from "../constants.js";

/*
 * Component that loads 20 images on the left side of the page. Imports from
 * the library called InfiniteScroll that required three states. images state
 * holds the length of images that need to be rendered, hasMore is a boolean
 * whether there are still more images to load when user scrolls down, and
 * reversedImages containing a reverse list of images. Used flex to display the
 * various images. When user clicks on an image, passes id and file key of that
 * image to ImageViewer component.
 */

const loadImages = 20;
const cloudFrontUrl = constants.REACT_APP_CLOUDFRONT_FILE_URL;

class Gallery extends React.Component {
  state = {
    images: Array.from(
      this.props.imageList.length < loadImages
        ? { length: this.props.imageList.length }
        : { length: loadImages }
    ),
    hasMore: this.props.imageList.length < loadImages ? false : true,
    reversedImages: this.props.imageList.slice(0).reverse(),
  };

  // Function when hasMore is true, handles whether there is more than 20 photos still needed
  // to load and assigning that value to state images length, if less, then assigning
  // the state images length with that value and changing hasMore to false.
  fetchMoreData = () => {
    if (this.state.images.length >= this.state.reversedImages.length) {
      this.setState({ hasMore: false });
      return;
    }
    setTimeout(() => {
      this.setState({
        images: this.state.images.concat(
          Array.from(
            this.state.reversedImages.length - this.state.images.length <
              loadImages
              ? {
                  length:
                    this.state.reversedImages.length - this.state.images.length,
                }
              : { length: loadImages }
          )
        ),
      });
    }, 1000);
  };

  createFileUrl = (file_key) => {
    return cloudFrontUrl + this.props.monitorId + "/fotos/" + file_key;
  };

  createFileAlt = (file_key) => {
    return this.props.monitorId + "/fotos/" + file_key;
  };

  render() {
    return (
      <div id="scrollableDiv" className="text-gray-700 overflow-y-scroll pt2">
        <InfiniteScroll
          dataLength={this.state.images.length}
          next={this.fetchMoreData}
          hasMore={this.state.hasMore}
          loader={
            <h4 className="text-center bg-white">
              {this.state.reversedImages.length === 0
                ? "No images"
                : "Loading..."}
            </h4>
          }
          scrollThreshold="90%"
          scrollableTarget="scrollableDiv"
          initialScrollY="25"
        >
          <div className="container px-2 py-2 mx-auto">
            <div className="flex flex-wrap -m-1 md:-m-2">
              {this.state.images.map((_, index) => (
                <div
                  key={index}
                  className="flex flex-wrap w-1/2 cursor-pointer"
                >
                  <div className="w-full p-1 md:p-2">
                    <img
                      className="block object-cover object-center w-full h-full rounded-lg hover:border-4 border-green-600"
                      src={this.createFileUrl(
                        this.state.reversedImages[index].file_key
                      )}
                      alt={this.state.reversedImages[index].file_key}
                      onClick={(image) => {
                        this.props.callback(
                          image.target.alt,
                          this.state.reversedImages[index].time_stamp
                        );
                      }}
                    ></img>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </InfiniteScroll>
      </div>
    );
  }
}

export default Gallery;
