//Component that handles an animation whenever the website is loading a page.

const Loading = () => {
  let circleLoading = "h-16 w-16 bg-gradient-to-br from-red-600 to-purple-600 rounded-full";

  return (
    <div className="flex gap-14 justify-center items-center absolute top-0 right-0 bottom-0 left-0 bg-slate-300">
      <div className={`${circleLoading} mr-1 animate-bounce`}></div>
      <div className={`${circleLoading} mr-1 animate-bounce200`}></div>
      <div className={`${circleLoading} animate-bounce400`}></div>
    </div>
  );
};

export default Loading;
