import image from "./warning.png";

// Component when user attempts to enter an invalid url.

const NotFound = () => {
  return (
    <div className="flex justify-center items-center absolute top-0 right-0 bottom-0 left-0 bg-black text-6xl gap-10 text-white">
      <img src={image} alt="warning" className="h-20" />
      <p>Page not found</p>
    </div>
  );
};

export default NotFound;
