import HomePageQueryLoader from "./Layout/HomePageFunction";
import NestPageQueryLoader from "./Layout/NestPageFunction";
import NotFound from "./components/NotFound";
import { Route, Routes } from "react-router-dom";

/*
 * Main JS file for the webpage. Imports react-router-dom to route path between three different pages.
 * HomePageQueryLoader: JS file that handles query for the list of nests (homepage).
 * NestPageQueryLoader: JS file that handles query for the page that displays information for one nest (nestpage).
 * NotFound: JS file for the website's default path whenever user attempts to enter an invalid url.
 */

function App() {
  return (
    <Routes>
      <Route path="/" replace element={<HomePageQueryLoader />} />
      <Route path="/nest/:id" element={<NestPageQueryLoader />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
