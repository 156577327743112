import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import * as constants from "./constants.js";
import App from "./App";
import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";
import { BrowserRouter } from "react-router-dom";

const client = new ApolloClient({
  uri: constants.REACT_APP_GRAPHQL_URL,
  cache: new InMemoryCache(),
});
// console.log(process.env);
// console.log(process.env.REACT_APP_GRAPHQL_URL);
// console.log(process.env.REACT_APP_CLOUDFRONT_FILE_URL);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ApolloProvider>
);
